html[data-theme='light'] {
    --primary: #007AFF;
    --primaryHover: #3395FF;
    --primaryPressed: #0062CC;
    --blueShade: #DBECFF;
    --blue2: #F5F9FF;
    --red: #FF6A55;
    --redShade: #FFE7E4;
    --green: #83BF6E;
    --greenShade: #E7F2E3;
    --yellow: #FFD966;
    --yellowShade: #FFF5D6;

    --grey1: #E3E4EB;
    --grey2: #EBEBF0;
    --grey3: #F2F2F5;
    --grey4: #FAFAFC;
    --grey5: #FAFAFC;
    --white: #FFFFFF;
    --dark1: #C7C8D9;
    --dark2: #8E90A6;
    --dark3: #555770;
    --dark4: #28293D;
    --black: #1A1D1F;

    --borderRadius: 4px;
}

html[data-theme='dark'] {
    --primary: #007AFF;
    --primaryHover: #3395FF;
    --primaryPressed: #0062CC;
    --blueShade: #DBECFF;
    --blue2: #F5F9FF;
    --red: #FF6A55;
    --redShade: #FFE7E4;
    --green: #83BF6E;
    --greenShade: #E7F2E3;
    --yellow: #FFD966;
    --yellowShade: #FFF5D6;

    --grey1: #E3E4EB;
    --grey2: #555770;
    --grey3: #1D2530;
    --grey4: #1D2530;
    --grey5: #FAFAFC;
    --white: #293142;
    --dark1: #C7C8D9;
    --dark2: #8E90A6;
    --dark3: #8E90A6;
    --dark4: #28293D;
    --black: #FFFFFF;

    --borderRadius: 4px;
}

.textXS {
    font-size: 0.75rem;
    line-height: 1.25rem;
}

.textSM {
    font-size: 0.875rem !important;
    line-height: 1.125rem;
}

.textMD {
    font-size: 1rem;
    line-height: 1.5rem;
}

.textLG {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.textXL {
    font-size: 1.5rem;
    line-height: 1.875rem;
}

.displayXS {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
}

.displaySM {
    font-size: 1.857rem;
    line-height: 2.325rem;
    font-weight: 400;
}

.displayMD {
    font-size: 2.25rem;
    line-height: 2.75rem;
    font-weight: 400;
}

.displayLG {
    font-size: 3rem;
    line-height: 3.75rem;
    font-weight: 400;
}

.displayXL {
    font-size: 3.75rem;
    line-height: 4.5rem;
    font-weight: 400;
}

.displayXL {
    font-size: 4.5rem;
    line-height: 5.625rem;
    font-weight: 400;
}

.grey1 {
    color: var(--grey1);
}

.grey2 {
    color: var(--grey2);
}

.grey3 {
    color: var(--grey3);
}

.grey4 {
    color: var(--grey4);
}

.grey5 {
    color: var(--grey5);
}

.dark1 {
    color: var(--dark1);
}

.dark2 {
    color: var(--dark2);
}

.dark3 {
    color: var(--dark3);
}

.dark4 {
    color: var(--dark4);
}

.dark5 {
    color: var(--dark5);
}

.white {
    color: var(--white);
}

.black {
    color: var(--black);
}

.btnSM {
    @extend .textSM;
    padding: 0.5rem 1rem;
}

.btnMD {
    @extend .textSM;
    padding: 0.5rem 0.875rem;
}

.btnLG {
    @extend .textMD;
    padding: 0.5rem 0.875rem;
}

.btnXL {
    @extend .textMD;
    padding: 0.75rem 1.25rem;
}

.btn2XL {
    @extend .textLG;
    padding: 1rem 1.75rem;
}

.btnPrimary {
    @extend .textSM;
    @extend .btnSM;
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
    font-weight: 500;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border-radius: var(--borderRadius);
}


.btnSecondary {
    @extend .textSM;
    @extend .btnSM;
    color: var(--primary);
    background-color: transparent;
    border: 1px solid var(--primary);
    font-weight: 500;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border-radius: var(--borderRadius);
}

.btnWhite {
    @extend .textSM;
    @extend .btnSM;
    color: var(--black);
    background: var(--white);
    border: 1px solid var(--grey1);
    font-weight: 500;
    box-shadow: 0px 3px 6px 0px #12121208;
    border-radius: var(--borderRadius);
}

.btnBlack {
    @extend .textSM;
    @extend .btnSM;
    color: var(--white);
    background: var(--black);
    border: 1px solid var(--black);
    font-weight: 500;
    box-shadow: 0px 3px 6px 0px #12121208;
    border-radius: var(--borderRadius);
}

.btnWhite:hover{
    color: var(--white);
    background-color: var(--black);
    border: 1px solid var(--black);
}

.btnPrimary:hover,
.btnSecondary:hover {
    color: var(--white);
    background-color: var(--primaryHover);
    border: 1px solid var(--primaryHover);
}

.btnPrimary:active,
.btnSecondary:active {
    color: var(--white);
    background-color: var(--primaryPressed);
    border: 1px solid var(--primaryPressed)
}

.btnPrimary:focus,
.btnWhite:focus,
.btnBlack:focus,
.btnSecondary:focus {
    box-shadow: 0px 0px 0px 1px #CCE4FF, 0px 1px 1px rgba(16, 24, 40, 0.05);
}

.btnPrimary:disabled,
.btnWhite:disabled,
.btnBlack:disabled,
.btnSecondary:disabled {
    color: var(--dark1);
    background-color: var(--grey2);
    border: none;
}

.shadowXS {
    box-shadow: 0px 1px 2px 0px #1018280D !important;
}

.shadowSM {
    box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A !important;
}

.shadowMD {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;
}

.shadowLG {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;
}

.shadowXL {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04) !important;
}

.shadow2XL {
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.25) !important;
}

.shadow3XL {
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.2) !important;
}

.performanceChartYrSegmented .ant-segmented-item {
    transition: all var(--primary) 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.performanceChartYrSegmented .ant-segmented-item-selected {
    background-color: var(--primary) !important;
    color: var(--white) !important;

}

.insightsTabs .ant-segmented-item {
    background-color: var(--grey3);
    margin-right: 1rem;
}

.insightsTabs .ant-segmented-item-selected {
    background-color: var(--primary) !important;
    color: var(--white) !important;

}

text {
    font-size: 0.725rem !important;
    color: var(--dark3) !important;
}