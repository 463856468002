.headerContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    max-width: 100%;
    max-width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;

}

.topLevelContainer {
    @extend .headerContainer;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.mainContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 1.25rem;
    max-width: 100%;
    max-width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
}

.footerBottom {
    margin: 1.5rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
}

.footerContent {
    margin: 3rem 2.5rem 1.5rem;
    display: flex;
    gap: 17.5rem;
    justify-content: space-between;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: calc(100vw - 5rem - 1.25rem - 20.813rem);
    /*padding-top: 1.25rem;
    padding-bottom: 1.25rem;*/

    max-width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 20.813rem !important;
    /*padding-top: 1.25rem;
    padding-bottom: 1.25rem;*/

    max-width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
}

.normalContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.25rem;
    /*padding-top: 1.25rem;
    padding-bottom: 1.25rem;*/
    max-width: 100%;
    max-width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
}

.pad20Container {
    padding: 1.25rem;
}

.pad8VerContainer {
    padding: 0.6rem 0rem;
}

.searchBox {
    background-color: #FAFAFA;
    border-color: #EAEAEA;
    padding: 0.75rem 0rem;
}


.wrapper {
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(3, 1fr);
}


.pad20Side10VerContainer {
    padding: 0.625rem 1.25rem;
}


.pad20Side6VerContainer {
    padding: 0.375rem 1.25rem;
}


.borderAll {
    border: 0.5px solid var(--grey3);
}

.borderTop {
    border-top: 1px solid var(--grey3);
}


.borderBottom {
    border-bottom: 1px solid var(--grey3);
}


.borderLeft {
    border-left: 1px solid var(--grey3);
}


.borderRight {
    border-right: 1px solid var(--grey3);
}