.compare-table {
    width: 100%;
    border-radius: var(--borderRadius);
    border-bottom: none;
}

.compare-table .center-label {
    text-align: center;
    font-size: 0.75rem;
    width: 10rem !important;
    /* box-shadow: inset 0 1em 2em var(--compare); */
    background-color: var(--compare);
    border: none;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0.5rem;
    font-weight: 400;
}

.compare-table tr {
    display: flex;
    width: 100%;
    padding: 0rem;
    border: none;
}


.compare-table th {
    line-height: unset;
}

.compareBestValue,
.compare-table tr td {
    text-align: center;
    flex: 1;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.75rem;
}

.compare-table thead tr:last-child th {
    border: none
}


.compare-collapse {
    margin-bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--lightGrey);
    border-radius: 0;
    border-bottom: 1px solid var(--border);
}

.fundColor0 {
    box-shadow: inset 0 -3em 7em #4284f3;
}

.fundColor1 {
    box-shadow: inset 0 -3em 7em #fabc05;
}

.fundColor2 {
    box-shadow: inset 0 -3em 7em #24c1e0;
}

.fundColor3 {
    box-shadow: inset 0 -3em 7em #fa7b16;
}

.loadingModal .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
}

.stick-to-top {
    position: sticky;
    top: 64px;
    background-color: var(--white);
    z-index: 200;
}

.compareBestValue {
    font-weight: 500;
    border: 1px dotted var(--green) !important;
    padding: 0.25rem;
    min-width: 10rem;
}