.header-link {
    padding: 0.5rem;
    text-decoration: none;
    color: var(--black);
}

.header-link:hover {
    font-weight: 500;
    cursor: pointer;
    color: var(--black);
}

.header-link.selected {
    font-weight: 600;
    border-bottom: 3px solid var(--black);
}

.dropdown-toggle::after {
    display: none;
}

.user-dropdown {
    background-color: var(--grey4);
    box-shadow: 0 3px 12px 1px rgb(51 51 51 / 15%);
    border: 1px solid var(--border);
}

.dropdown-list {
    border-bottom: 1px solid var(--border);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.dropdown-list:last-child {
    border-bottom: none;
}

.dropdown-item {
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 1rem
}

.custom-details-table {
    display: flex;
    gap: 0.75rem;
}

.custom-detail {
    padding: 0.625rem 0;
    flex: 1;
    background-color: var(--grey4);
    border-radius: var(--borderRadius);
}

.custom-value {
    @extend .dark3;
    @extend .textSM;
    @extend .w-700;
}

.custom-label {
    @extend .textXS;
    @extend .dark2;
    margin-bottom: 0.2rem;
}

.opinionModal {
    position: fixed !important;
    margin: 0 !important;
    width: 380px !important;
    height: 100% !important;
    -webkit-transform: translate3d(0%, 0, 0) !important;
    -ms-transform: translate3d(0%, 0, 0) !important;
    -o-transform: translate3d(0%, 0, 0) !important;
    transform: translate3d(0%, 0, 0) !important;
    right: -380px !important;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    transition: opacity 0.3s linear, right 0.3s ease-out !important;
}

.opinionModal .modal-content {
    height: 100% !important;
    overflow-y: auto !important;
}

.modal.fade.show .opinionModal {
    right: 0 !important;
}

.matrix-table {
    text-align: center;
    white-space: nowrap;
}

.matrix-table td.boxContainer {
    height: 5.5rem;
    width: 5.5rem;
    padding: 0.5rem;
}

.matrix-table td .box {
    border: 1px solid var(--dark1);
    border-radius: var(--borderRadius);
    height: 100%;
    width: 100%;
}

/*
.dashCardAnimate{
    transition: all 400ms;
}
.dashCardAnimate:hover{
    border-bottom-right-radius: 80%;
    font-weight: 600;
}
*/

.checklist-custom-collapse {
    background-color: #ffffff;
}

/*
.checklist-custom-collapse .checklist-custom-panel {
    margin-bottom: 1rem;
}
*/
.checklist-custom-collapse .checklist-custom-panel .ant-collapse-header {
    align-items: center;
}


.rowHighlightLevel2 td,
.rowHighlightLevel2:hover td {
    background-color: #ffeec3 !important;
    font-weight: 600 !important;
}

.rowHighlightLevel1 td,
.rowHighlightLevel1:hover td {
    background-color: #fff7e2 !important;
    font-weight: 600 !important;
}


.brand-logo {
    margin-bottom: 2rem;

    img {
        width: 150px;
    }
}



/* Auth */


.lock-profile-img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
}

.brand-logo {
    margin-bottom: 2rem;

    img {
        width: 150px;
    }
}

.insideTable thead th {
    padding: 0.4rem !important;
}

.insideTable tbody tr td {
    padding: 0.4rem !important;
}

.tickerTable thead th {
    padding: 1rem 2rem !important;
}

.tickerTable tbody tr td {
    padding: 1rem 2rem !important;
}


.normalInput {
    outline: none;
    border: none;
    border-bottom: 1px solid #333;
    margin-top: 2%;
    margin-bottom: 4%;
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    caret-color: var(--primary);
    background: transparent;
}

.normalInput:focus {
    border-bottom: 1px solid var(--primary);
}

.normalInput::placeholder {
    font-size: 24px;
    font-weight: 500;
    color: #6c6c6c;
}

.normalInput.rupee {
    background-image: url("https://d3etfstcuyoos5.cloudfront.net/app-images/imgs/rupee.webp");
    padding-left: 20px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position-y: 9px;
}

.normalInput:disabled {
    color: var(--black);
    background-color: transparent
}

// custom progress circle
.customProgress .ant-progress-inner {
    width: var(--prWidth) !important;
    height: var(--prHeight) !important;
    font-size: var(--prFont) !important;
    color: var(--black);
    font-weight: 500;
}

// .customProgress .ant-progress-circle-path {
//     stroke-width: var(--strWidth);
//     stroke-linecap: round;
//     stroke-linejoin: round;
// }

// .customProgress .ant-progress-circle-trail {
//     stroke-width: var(--strWidth);
//     stroke: var(--grey3) !important;
// }

//  Table pagination
:where(.css-dev-only-do-not-override-1yhwldv).ant-pagination .ant-pagination-item.ant-pagination-item-active {
    @extend .textSM;
    @extend .w-500;
    border-radius: 50%;
    background-color: var(--black);
    color: var(--white) !important;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

:where(.css-dev-only-do-not-override-1yhwldv).ant-pagination .ant-pagination-item {
    @extend .textSM;
    border-radius: 2rem;
    color: var(--dark4);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        @extend .w-500;
        background-color: var(--blueShade) !important;
        color: var(--primary) !important;
    }
}

.ant-pagination-prev {
    position: relative;
    padding-left: calc(0.6rem + 5px);

    >:first-child {
        color: var(--dark3);

        &::before {
            content: "";
            position: absolute;
            height: 0.4rem;
            width: 0.4rem;
            border-top: 2px solid var(--dark3);
            border-left: 2px solid var(--dark3);
            top: 50%;
            transform: translate(0%, -50%) rotate(315deg);
            left: 10px;
            border-radius: 0.05rem;
        }
    }

    &.ant-pagination-disabled {
        >:first-child {
            color: var(--dark1);

            &::before {
                border-color: var(--dark1);
            }
        }

        &:hover {
            background-color: var(--grey4) !important;
            cursor: pointer;

            >:first-child {
                color: var(--dark1) !important;
            }

            >:first-child::before {
                border-color: var(--dark1) !important;
            }
        }
    }

    &:hover {
        @extend .w-500;
        background-color: var(--blueShade) !important;
        border-radius: 2rem;

        >:first-child {
            color: var(--primary) !important;
        }

        >:first-child::before {
            border-color: var(--primary) !important;
        }
    }
}

.ant-pagination-next {
    position: relative;
    padding-right: calc(0.6rem + 5px);

    >:first-child {
        color: var(--dark3);

        &::before {
            content: "";
            position: absolute;
            height: 0.4rem;
            width: 0.4rem;
            border-top: 2px solid var(--dark3);
            border-left: 2px solid var(--dark3);
            top: 50%;
            transform: translate(0%, -50%) rotate(135deg);
            right: 10px;
            border-radius: 0.05rem;
        }
    }

    &.ant-pagination-disabled {
        >:first-child {
            color: var(--dark1);

            &::before {
                border-color: var(--dark1);
            }
        }

        &:hover {
            background-color: var(--grey4) !important;
            cursor: pointer;

            >:first-child {
                color: var(--dark1) !important;
            }

            >:first-child::before {
                border-color: var(--dark1) !important;
            }
        }
    }

    &:hover {
        @extend .w-500;
        background-color: var(--blueShade) !important;
        border-radius: 2rem;

        >:first-child {
            color: var(--primary) !important;
        }

        >:first-child::before {
            border-color: var(--primary) !important;
        }
    }
}

// Antd Checkbox styles
.ant-checkbox-wrapper {
    .ant-checkbox {
        .ant-checkbox-inner {
            border: 1px solid var(--dark1);
            width: 1rem;
            height: 1rem;
            border-radius: 0.25rem;

            &:hover {
                border: 1px solid var(--dark1);
                border-color: var(--dark1);
            }

            &::after {
                width: 0.3rem;
                height: 0.58rem;
                border-width: 0.125rem;
            }
        }

        &.ant-checkbox-checked {
            .ant-checkbox-inner {
                border: 1px solid var(--primary)
            }
        }
    }

    &:hover {
        .ant-checkbox-inner {
            border: 1px solid var(--dark1) !important;
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                border: 1px solid var(--primary) !important;
            }
        }
    }

    &:focus,
    &:active {
        .ant-checkbox-inner {
            outline: 2px solid var(--primary);
            outline-offset: 0.125rem;
        }
    }

    span {
        @extend .textSM;
        @extend .w-500;
    }
}

// Radio styles
.ant-radio-wrapper {
    .ant-radio {
        .ant-radio-inner {
            border: 1px solid var(--dark1);
        }

        &.ant-radio-checked {
            .ant-radio-inner {
                border: 1px solid var(--primary)
            }
        }
    }

    &:hover {
        .ant-radio-inner {
            border: 1px solid var(--dark1) !important;
        }

        .ant-radio-checked {
            .ant-radio-inner {
                border: 1px solid var(--primary) !important;
            }
        }
    }

    &:focus,
    &:active {
        .ant-radio-inner {
            outline: 2px solid var(--primary);
            outline-offset: 0.125rem;
        }
    }

    span {
        @extend .textSM;
        @extend .w-500;
    }
}

.week52slider {

    .ant-slider-rail {
        background-color: var(--primary) !important;
    }
}