// Instrument List Search --> InstrumentListSearch.js
.insListSearch {
    width: 100%;
    position: relative;

    .insListSearchInput {
        width: 100%;
        padding: 0.4rem 0.7rem;

        input {
            @extend .textXS;
            color: var(--dark3);
            background-color: transparent !important;
        }
    }

    .insListContainer {
        @extend .shadowLG;
        background-color: var(--white);
        border: 1px solid var(--grey4);
        border-radius: 0.5rem;
        position: absolute;
        z-index: 900;
        min-width: 22rem;
        max-width: 22rem;
        top: calc(100% + 0.5rem);

        &.left {
            left: 0px;
        }

        &.right {
            right: 0px;
        }

        >.insListContent {
            max-height: 20rem;
            overflow: auto;

            >div {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                justify-content: space-between;
                padding: 0.625rem 0.875rem;
                cursor: pointer;
                width: 100%;
                overflow: hidden;

                >div {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    overflow: hidden;

                    >div {
                        white-space: nowrap !important;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &:hover {
                    @extend .w-500;
                    background-color: var(--grey4);
                }

                &.insListItemSelected {
                    @extend .w-500;
                    background-color: var(--blue2);
                }

                img {
                    width: 0.8rem;
                }
            }
        }

        >.insListFooter {
            padding: 0.625rem 0.875rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

// Custom checkbox styles --> Checkbox.js
.customCheckbox {
    position: relative;
    width: 1rem;
    height: 1.4rem;
    cursor: pointer;
    background-color: transparent;
    padding: 0.2rem 0px;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .customCheckboxInput {
        opacity: 0;
        cursor: pointer;
        height: 1rem;
        width: 1rem;
        background-color: transparent;
        z-index: 2;

        &:checked~.customCheckmark {
            background-color: var(--primary);
            border-color: var(--primary);

            &::after {
                display: block;
            }
        }

        &:active~.customCheckmark {
            outline: 2px solid var(--primary);
            outline-offset: 0.125rem;
            transition: all 0.3s;
        }
    }

    .customCheckmark {
        // z-index: -1;
        position: absolute;
        top: 0.2rem;
        left: 0;
        height: 1rem;
        width: 1rem;
        background-color: var(--white);
        border: 0.0625rem solid var(--dark1);
        border-radius: 0.25rem;

        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 0.1rem;
            left: 0.25rem;
            width: 0.35rem;
            height: 0.6rem;
            border: solid white;
            border-width: 0 0.13rem 0.13rem 0;
            border-radius: 0.05rem;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .customCheckboxLabel {
        @extend .textSM;
        @extend .w-500;
    }
}

// Custom Tooltip styles --> Tooltip.js
.tooltipContainer {
    position: relative;
    display: inline-block;

    .tooltipText {
        @extend .shadowLG;
        @extend .textSM;
        @extend .w-500;
        visibility: hidden;
        min-width: 7rem;
        max-width: 20px;
        background-color: var(--white);
        color: var(--black);
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
        text-align: center;

        &.top {
            &.tacenter {
                bottom: 115%;
                left: 50%;
                transform: translate(-50%, 0%);
            }

            &.taleft {
                bottom: 115%;
                left: 0%;
                transform: translate(0%, 0%);
            }

            &.taright {
                bottom: 115%;
                right: 0%;
                transform: translate(0%, 0%);
            }

            &.true.tacenter::after {
                content: " ";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: var(--white) transparent transparent transparent;
            }

            &.true.taleft::after {
                content: " ";
                position: absolute;
                top: 100%;
                left: 15%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: var(--white) transparent transparent transparent;
            }

            &.true.taright::after {
                content: " ";
                position: absolute;
                top: 100%;
                right: 10%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: var(--white) transparent transparent transparent;
            }
        }

        &.bottom {
            &.tacenter {
                top: 115%;
                left: 50%;
                transform: translate(-50%, 0%);
            }

            &.taleft {
                top: 115%;
                left: 0%;
                transform: translate(0%, 0%);
            }

            &.taright {
                top: 115%;
                right: 0%;
                transform: translate(0%, 0%);
            }

            &.true.tacenter::after {
                content: " ";
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent var(--white) transparent;
            }

            &.true.taleft::after {
                content: " ";
                position: absolute;
                bottom: 100%;
                left: 15%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent var(--white) transparent;
            }

            &.true.taright::after {
                content: " ";
                position: absolute;
                bottom: 100%;
                right: 10%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent var(--white) transparent;
            }
        }

        &.left {
            right: 105%;
            top: 50%;
            transform: translate(0%, -50%);

            &.true::after {
                content: " ";
                position: absolute;
                top: 50%;
                left: 100%;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent transparent var(--white);
            }

        }

        &.right {
            left: 105%;
            top: 50%;
            transform: translate(0%, -50%);

            &.true::after {
                content: " ";
                position: absolute;
                top: 50%;
                right: 100%;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent var(--white) transparent transparent;
            }
        }
    }

    &:hover .tooltipText {
        visibility: visible;
        opacity: 1;
    }
}

//Custom Select styles --> DropDown.js
.customSelect {
    .ant-select-selector {
        background: var(--white) !important;
        border: 1px solid var(--grey2) !important;
        height: 2rem !important;
        padding: 0px 0.7rem !important;
        border-radius: 0.25rem;
        width: 100%;

        >.ant-select-selection-search {
            >input {
                @extend .textSM;
                height: 1.875rem !important;
            }
        }

        >.ant-select-selection-overflow {
            width: 100%;
            padding: 0px;
            height: 1.875rem !important;

            .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
                // width: 100%;
                padding: 0px;
                height: 1.875rem !important;

                .ant-select-selection-search {
                    // width: 100% !important;
                    margin: 0px !important;
                    height: 1.875rem !important;
                    padding: 0px;

                    >input {
                        @extend .textSM;
                        height: 1.875rem !important;
                        padding: 0px;
                        margin: 0px;
                    }
                }
            }

            .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                    height: 1.5rem !important;
                    align-items: center;
                    padding-inline-start: 0.5rem;
                    padding-inline-end: 0.25rem;
                    border-radius: 0.25rem;
                    margin: 0.125rem 0px;
                    margin-inline-end: 0.25rem;

                    .ant-select-selection-item-remove {
                        font-size: 0.625rem;
                    }
                }
            }
        }

        >.ant-select-selection-placeholder {
            @extend .textSM;
            line-height: 1.875rem !important;
        }
    }

    &.ant-select-multiple .ant-select-selector {
        display: block !important;
    }

    .ant-select-arrow {
        svg {
            font-size: 0.875rem;
        }
    }

    .ant-select-clear {
        background-color: transparent !important;
    }

    &.ant-select {
        // @extend .shadowXS;
        display: flex !important;
        align-items: center !important;

    }

    .ant-select-selection-item {
        @extend .textSM;
        color: var(--dark4);
        line-height: 1.875rem !important;

        img {
            object-fit: contain;
        }
    }
}

.ant-select-dropdown {
    @extend .shadowXL;
    background: var(--white);
    border: 1px solid var(--grey4);
    box-sizing: border-box;
    padding: 0px;
    min-width: fit-content !important;
    max-width: 20rem;
    max-height: 20rem;
    overflow-y: auto;
    // z-index: 900;

    // .ant-select-item.ant-select-item-option {
    //     @extend .textSM;
    //     @extend .w-500;
    //     background-color: var(--white);
    //     color: var(--dark4);
    //     padding: 0.5rem 0.75rem;
    //     min-height: auto;
    //     border-radius: 0px;
    //     min-width: fit-content;
    //     padding-right: 3rem;

    //     &.ant-select-item-option-active {
    //         background-color: var(--grey4);
    //         color: var(--dark3);
    //     }

    //     &.ant-select-item-option-selected {
    //         background-color: var(--grey4);
    //         color: var(--dark4);
    //         position: relative;

    //         >:first-child::before {
    //             content: "";
    //             position: absolute;
    //             height: 0.5rem;
    //             width: 0.8rem;
    //             background-image: url('../Assets/icons/checkmark.png');
    //             background-size: contain;
    //             background-repeat: no-repeat;
    //             // border-bottom: 0.1rem solid var(--black);
    //             // border-left: 0.1rem solid var(--black);
    //             transform: translate(0%, -50%);
    //             // border-radius: 0.05rem;
    //             right: 1.2rem;
    //             top: 50%;
    //         }
    //     }

    //     &.ant-select-item-option-disabled {
    //         background-color: var(--white);
    //         color: var(--grey3);
    //     }

    //     .ant-select-item-option-state {
    //         display: none !important;
    //     }
    // }

    // .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
    //     display: var(--scrollbar) !important;

    //     .rc-virtual-list-scrollbar-thumb {
    //         background-color: var(--grey3) !important;
    //     }
    // }
}

// Week52slider styles --> Slider52Week.js
.week52slider {
    width: 100%;
    position: relative;

    >.week52slidertrack {
        height: 0.5rem;
        width: 100%;
        border-radius: 0.25rem;
        background-color: var(--primary);


    }

    >.week52sliderpoint {
        &::after {
            content: " ";
            position: absolute;
            top: 0.125rem;
            left: calc(var(--left) - 0.5rem);
            // transform: translateX(-50%);
            border-width: 0.5rem;
            border-style: solid;
            border-color: transparent transparent var(--primary) transparent;
        }
    }
}

//Blog and faqs 
.blogAndFaqs {
    .ant-collapse-header {
        padding: 0.75rem 0rem !important;
        align-items: center !important;

        .ant-collapse-expand-icon {
            @extend .textSM;
            padding: 0px 0px 0px 0.75rem !important;
            height: 1.05rem !important;

            svg {
                @extend .textSM;
            }
        }
    }

    .ant-collapse-content-box {
        padding: 0rem 0rem 1rem !important;
    }
}

// Custom dropdown styles
.customDropdown {
    width: fit-content;
    position: relative;

    .customDropdownInput {
        width: 20rem;
        height: 2rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        border: 1px solid var(--grey2);
        background-color: var(--white);
        border-radius: 0.25rem;
        padding: 0px 0.875rem;
        cursor: pointer;

        &.focustrue {
            outline: 2px solid var(--blueShade);
        }

        .customDropdownInpCont {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 0.2rem;
            justify-content: space-between;
            width: 100%;

            .multipleSelectedContainer {
                display: flex;
                align-items: center;
                gap: 0.2rem;
                max-width: 50%;

                >div {
                    @extend .textXS;
                    display: flex;
                    align-items: center;
                    border: 1px solid var(--dark1);
                    background-color: var(--grey2);
                    border-radius: 0.25rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: 0.1rem;

                    &.firstItem {
                        flex-shrink: 1;
                    }

                    &.otherItems {
                        flex-grow: 1;
                        padding: 0.1rem 0.2rem;
                        flex-shrink: 0;
                    }

                    >span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &~.inputCDT {
                    flex-shrink: 0 !important;
                    min-width: 25%;
                    flex-grow: 1;
                    max-width: 35%;

                    input {
                        width: 100%;
                    }
                }
            }

            .inputCDT {
                flex: 1;
                position: relative;
                display: flex;
                align-items: center;

                input {
                    @extend .textSM;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    flex: 1;
                }

                .customDropdownSelectedValue {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 900;
                    left: 0.2rem;
                    background-color: var(--white);
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &.inactive {
                        opacity: 0.8;

                        &~input::placeholder {
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }

    .customDropdownList {
        @extend .shadowLG;
        max-height: 20rem;
        overflow: auto;
        width: 100%;
        position: absolute;
        top: calc(100% + 0.3rem);
        z-index: 900;
        background-color: var(--white);
        border-radius: 0.5rem;

        .customDropdownItem {
            @extend .textSM;
            @extend .w-500;
            padding: 0.625rem 0.875rem;
            cursor: pointer;
            color: var(--dark4);
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.2rem;

            &:hover {
                background-color: var(--grey4);
                color: var(--dark3);
            }

            &.selected-item-true {
                background-color: var(--grey4);
            }

            img {
                width: 0.85rem;
                height: 0.6rem;
            }
        }
    }
}