/*
html[data-theme='light'] {
    --background: #FAFAFC;
    --backgroundCard: #fcfcfc;
    --headerBg: #FFFFFF;
    --textDark: #1A1D1F;
    --textLight: #87868D;
    --border: #e5e5e5;
    --dashCardBackground: linear-gradient(93.94deg, #000B16 0%, #13213A 100%);
    --primary: #007AFF;
    --primaryLite: #3395FF;
    --primaryDark: #0062CC;
    --blueShade: #E0EFFF;
    --primaryBg: #FFF9EA;
    --secondary: #455A64;
    --green: #58985F;
    --darkGreen: #285E4D;
    --teal: #6CD3C2;
    --purple: #9F87E8;
    --yellow: #FDD504;
    --red: #FF8080;
    --black: #000B16;
    --grey: #6C6C6C;
    --white: #ffffff;
    --indianEquity: #294C88;
    --debt: #00A6A4;
    --internationalEquity: #F97B30;
    --gold: #FAAD3B;
    --cash: #74BA85;
    --highestRisk: #FFE7E5;
    --secondHeighestRisk: #FFF1DD;
    --moderateRisk: #FFFBD3;
    --conservativeRisk: #F0F6D5;
    --lowestRisk: #EDFAFF;
    --borderRadius: 4px;
    --lightBlueBG: #DBECFF;

    --btnFilledDarkBG: #000B16;
    --btnFilledDarkText: #fff;

    --btnFilledLightBG: #fff;
    --btnFilledLightText: #000B16;

    --tableHoverColor: #252B3411;
    --ant-primary-color-hover: red;

    --bs-link-color: #0080FD;
    --smallCardBlueBg: #EDFAFF;

    --compare: #f5f5f0;
    --lightGrey: #EEEEEE;

    --hoverBackground: #fafafa;
    --kbBg: #5B6C7E;
    --kbFontColor: #5B6C7E;

}

html[data-theme='dark'] {
    --background: #111;
    --backgroundCard: #1f1f1f;
    --headerBg: #FFFFFF;
    --textDark: #ffffff;
    --textLight: #f1f1f1;
    --border: #272727;
    --dashCardBackground: linear-gradient(93.94deg, #000B16 0%, #13213A 100%);
    --primary: #FFECBD;
    --primaryDark: #0062CC;
    --primaryLite: #3395FF;
    --primaryBg: #FFB70028;
    --secondary: #48C9FD;
    --green: #58985F;
    --darkGreen: #285E4D;
    --teal: #6CD3C2;
    --purple: #9F87E8;
    --yellow: #FDD504;
    --red: #FF8080;
    --black: #000B16;
    --grey: #6C6C6C;
    --white: #ffffff;
    --indianEquity: #294C88;
    --debt: #00A6A4;
    --internationalEquity: #F97B30;
    --gold: #FAAD3B;
    --cash: #74BA85;
    --highestRisk: #FFE7E5;
    --secondHeighestRisk: #FFF1DD;
    --moderateRisk: #FFFBD3;
    --conservativeRisk: #F0F6D5;
    --lowestRisk: #EDFAFF;
    --borderRadius: 5px;
    --lightBlueBG: #DBECFF;
    --btnFilledDarkBG: #343434;
    --btnFilledDarkText: #000B16;

    --btnFilledLightBG: #000B16;
    --btnFilledLightText: #fff;

    --tableHoverColor: #000B16;

    --bs-link-color: #48C9FD;
    --smallCardBlueBg: #1f1f1f;
    --compare: #1f1f1f;
    --lightGrey: #232323;

    --hoverBackground: #1f1f1f;
}

*/
* {
    font-family: 'DM Sans', sans-serif;

    ::-webkit-scrollbar {
        display: none;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    html {
        font-size: 6px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    html {
        font-size: 7.5px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 798px) {
    html {
        font-size: 8px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 854px) {
    html {
        font-size: 8.5px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 994px) {
    html {
        font-size: 10.5px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    html {
        font-size: 15px;
    }
}

@media only screen and (min-width: 1440px) {
    html {
        font-size: 16px;
    }
}

a {
    text-decoration: none;

}

.fontH1 {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2rem;
}

.fontH2 {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.fontC1 {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.2rem;
}

.fontC2 {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 1.05rem;
}

.fontH1WOLineHeight {
    font-size: 1.3rem;
    font-weight: 500;
}

.fontH2WOLineHeight {
    font-size: 0.9rem;
    font-weight: 400;
}

.fontC1WOLineHeight {
    font-size: 0.7rem;
    font-weight: 400;
}

.fontC2WOLineHeight {
    font-size: 0.6rem;
    font-weight: 400;
}

.textDark {
    color: var(--black);
}

.textLight {
    color: var(--dark2);
}

.w-bold {
    font-weight: bold;
}

.w-500 {
    font-weight: 500 !important;
}

.w-600 {
    font-weight: 600 !important;
}

.w-700 {
    font-weight: 700;
}

.w-normal {
    font-weight: normal;
}


.primary-link:hover {

    cursor: pointer;
}

.hoverBold:hover {
    cursor: pointer;
    font-weight: 500 !important;
}

.ant-card {
    box-shadow: none !important;
    background-color: var(--white);
}

.ant-card .ant-card-body {
    padding: 1.25rem;
}

.antd-table-custom-class thead th,
.antd-table-custom-class tbody td {
    white-space: nowrap;
    word-break: break-word;
    word-break: break-all
}

.table-wrapper table {
    table-layout: fixed !important;
    /* rewrite inline styles */
}




.left-sidebar .ant-tabs-nav {
    padding: 0.1rem 1rem;
    background: var(--white);
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;
    color: white;
    font-weight: 500;
    margin-bottom: 0;
}

.ant-tabs-nav {
    margin-bottom: 0 !important;
}

.internal-tabs .ant-tabs-tab {
    padding-left: 0 !important;
    padding-right: 0.75rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.internal-tabs .ant-tabs-tab-btn {
    text-align: left;
    white-space: pre-wrap;
    width: 10rem;
    font-size: 0.875rem;
}

.internal-tabs .ant-tabs-tabpane {
    padding-top: 0.75rem;
    padding-left: 2.5rem !important;
    padding-right: 1rem !important;
}

.grand-child-tabs .ant-tabs-tab {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    padding-top: 0rem !important;
    padding-bottom: 0.75rem !important;
}

.grand-child-tabs .ant-tabs-tab-btn {
    text-align: center;
    white-space: pre-wrap;
    width: auto;
    font-size: 0.875rem;
}

.grand-child-tabs .ant-tabs-tabpane {
    padding-top: 0.75rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.special-internal-tabs .ant-tabs-tab {
    padding-left: 0.75rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
}

.special-internal-tabs .ant-tabs-tab-disabled {
    cursor: unset !important;
    font-weight: 500 !important;
    color: var(--black) !important;
    padding-left: 0 !important;
}

.special-internal-tabs .ant-tabs-tab-btn {
    text-align: left;
    white-space: pre-wrap;
    width: 10rem;
    font-size: 0.75rem !important;
}

.special-internal-tabs .ant-tabs-tab-disabled .ant-tabs-tab-btn {
    font-size: 0.875rem !important;
    padding-top: 0.25rem !important;
}

.special-internal-tabs .ant-tabs-tabpane {
    padding-top: 0.15rem !important;
    padding-left: 2rem !important;
    padding-right: 0rem !important;
}

.ant-tabs-content-holder {
    border-left: none !important;
}

.ant-tabs-tabpane {
    padding-left: 1.25rem !important;
}

.ant-pagination-item-link {
    display: flex !important;
    align-items: center !important;
}


.hidden-scrollbar {
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
    /*chrome and Safari */
}

.menu-item {
    color: var(--dark2);
}

.menu-item:hover {
    color: var(--black);
}

.menu-item-selected {
    color: var(--primary) !important;
}

.btnFilledDark {
    color: var(--btnFilledDarkText);
    background-color: var(--btnFilledDarkBG);
    border: none;
    font-weight: 500;
    font-size: 0.8rem;
}

.btnFilledDark:hover {
    color: var(--primary) !important;
    transform: translateY(-0.5px);
}

.btnFilledLight {
    color: var(--btnFilledLightText);
    background-color: var(--btnFilledLightBG);
    font-weight: 500;
    font-size: 0.8rem;
}

.btnFilledLight:hover {
    color: var(--btnFilledDarkText) !important;
    background-color: var(--btnFilledDarkBG) !important;
    border-color: var(--btnFilledDarkBG) !important;
}

.btnFilledDisabled {
    color: var(--btnFilledLightText);
    background-color: var(--btnFilledLightBG);
    opacity: 0.6 !important;
    font-weight: 500;
    font-size: 0.8rem;
}

.btnFilledDisabled:hover {
    color: var(--btnFilledLightText) !important;
    background-color: var(--btnFilledLightBG) !important;
    opacity: 0.2 !important;
}


.ant-segmented {
    background-color: var(--grey3);
    color: var(--dark2);
}

.ant-segmented-item-label {
    font-size: 0.685rem;
}

.ant-table {
    border: 1px solid var(--grey3) !important;
    border-bottom: none !important;
    border-radius: var(--borderRadius) !important;
}

th.ant-table-cell {
    @extend .textSM;
    @extend .black;
    @extend .w-500;
    background-color: var(--grey5) !important;
    padding: 0.75rem !important;
    font-size: 0.813rem !important;
    border-color: var(--grey3) !important;
}

td.ant-table-cell {
    @extend .textSM;
    @extend .dark3;
    @extend .w-500;
    padding: 0.75rem !important;
    font-size: 0.813rem !important;
    border-color: var(--grey3) !important;
}

.ant-table-container table>thead>tr:first-child th:first-child {
    border-start-start-radius: unset !important
}

.ant-table-container table>thead>tr:first-child th:last-child {
    border-start-end-radius: unset !important
}

.ant-ribbon-text {
    font-size: 0.6rem;
}

.ant-ribbon {
    font-size: 0.6rem;
    line-height: 0.7rem;
    padding: 0 0.3rem;
    height: 1rem;
    vertical-align: middle;
}

.table {
    border-top: none;
}

.hoverBackground:hover {
    background-color: var(--hoverBackground);
    cursor: pointer;
}



@keyframes fadeIn {
    from {
        opacity: 0;
        translate: 1;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 1s;
}


.animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(3%, 0, 0);
        transform: translate3d(3%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(3%, 0, 0);
        transform: translate3d(3%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}


/*=== FADE IN LEFT ===*/
@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-3%, 0, 0);
        transform: translate3d(-3%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-3%, 0, 0);
        transform: translate3d(-3%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

.custom-small-progressbar .ant-progress {
    margin-bottom: 0;
}

.custom-small-progressbar .ant-progress-text {
    font-size: 0.6rem;
}

.ant-popover-inner-content {
    width: 20rem !important
}

.ant-table-column-sorter {
    visibility: hidden;
    padding: 0;
    margin: 0;
    width: 0;
}

.scoreboardTabs .ant-tabs-tab-btn {
    font-size: 0.9rem;
    font-weight: 500;
}

.disabledDiv {
    pointer-events: none !important;
    opacity: 0.4 !important;
    overflow-y: hidden !important;
    touch-action: none !important;
}



.ant-select-selector {
    /* Grey 4 */

    background: var(--grey4) !important;
    /* Grey 3 */
    border: 1px solid var(--grey3) !important;
}

.selectWhite {
    .ant-select-selector {
        /* Grey 4 */

        background: var(--white) !important;
        /* Grey 3 */
        border: 1px solid var(--grey3) !important;
    }
}

.ant-popover-inner-content {
    width: unset !important;
}

.ant-select-selection-item {
    @extend .textSM;
}

.highcharts-container,
.highcharts-container svg {
    width: 100% !important;
    font-size: 3rem !important;
}


i[class*='fi fi-rr-'],
i[class*='fi fi-rr-']::before {
    line-height: unset !important;
}



.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
    display: none;
}


.ant-table-body{
    overflow-x: hidden!important;
}

.ant-table-body:hover{
    overflow-x: scroll!important;
}


span.show-more-less-clickable{
    color: var(--red);
    font-weight: 500;
    text-decoration: none !important
}