.kbMidContainer {
    width: 100%;
}

.margin20Bottom {
    margin-bottom: 1.25rem;
}

.breadcrumbPrev {
    color: var(--dark3);
    text-transform: capitalize;
}

.breadcrumbPrev:hover {
    color: var(--black);
}

.breadcrumbCurr {
    color: var(--primary);
    text-transform: capitalize;
}

.resCategoryItem {
    margin: 0.8rem 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0.5rem;
    gap: 0.2rem;

    >a {
        color: var(--dark4);
        display: flex;
        align-items: center;
        gap: 0.3rem;

        &:hover {
            color: var(--dark2);
        }

        >:first-child {
            flex-shrink: 0;
        }
    }

    >span {
        color: var(--dark2);
    }
}

.cardHeader {
    color: var(--black);
}

.cardHeader:hover {
    color: var(--primary) !important
}

.blog-author {
    display: flex;
    align-items: center;
    gap: 10px;
}


#resource-container {
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0.4px;
    line-height: 130%;

    img {
        max-width: 100%;
        border-radius: var(--borderRadius);
    }

    a {
        color: var(--primary) !important;
    }
}

.prevNextCont {
    >a:last-child {
        color: var(--black);
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        >div:first-child {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            color: var(--dark2);
        }

        &:hover {
            color: var(--dark2);
        }
    }

    >a:first-child {
        color: var(--black);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        >div:first-child {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            color: var(--dark2);
        }

        &:hover {
            color: var(--dark2);
        }
    }
}

.searchContainer {
    z-Index: 10;
    width: min(100%, 450px);
    max-height: 50vh;
    overflow: auto;
    background-color: var(--white);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: var(--borderRadius);
    border: 1px solid var(--grey1);

    >div {
        border-radius: 0px;
        cursor: pointer;

        &:hover {
            background-color: var(--blue2);
            color: var(--black);
        }
    }
}

.h100 {
    height: 100%;
}

.w100 {
    width: 100%;
}

.pad2 {
    padding: 2rem;
}